import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Profile, UpdateProfile } from '@mp/auth/profile/domain';

export const ProfileActions = createActionGroup({
  source: 'Auth - Profile',
  events: {
    fetchProfile: emptyProps(),
    fetchProfileSuccess: props<{ profile: Profile }>(),
    fetchProfileError: emptyProps(),

    updateProfile: props<{ dto: UpdateProfile }>(),
    updateProfileSuccess: props<{ profile: Profile }>(),
    updateProfileError: emptyProps(),
  },
});
