import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Profile, UpdateProfile } from '@mp/auth/profile/domain';

import { ProfileActions } from './profile.actions';
import { selectProfile } from './profile.selectors';

@Injectable({ providedIn: 'root' })
export class ProfileFacade {
  readonly profile: Signal<Profile | null> = this.store$.selectSignal(selectProfile);

  readonly profile$: Observable<Profile | null> = this.store$.select(selectProfile);

  constructor(private readonly store$: Store) {}

  fetchProfile(): void {
    this.store$.dispatch(ProfileActions.fetchProfile());
  }

  updateProfile(dto: UpdateProfile): void {
    this.store$.dispatch(ProfileActions.updateProfile({ dto }));
  }
}
