/**
 * Describes the user types.
 */
export enum UserType {
  /**
   * A normal user that logs in via the identity server.
   */
  IdentityUser = 'IdentityUser',
  /**
   * A user that is logged in via API key.
   */
  ApiKey = 'ApiKey',
  /**
   * The system administrator user.
   *
   * **DO NOT USE IT WITHIN THE APPLICITION FOR PERMISSION CHECKS!**
   */
  SysAdmin = 'SysAdmin',
}
