import { createReducer, on } from '@ngrx/store';

import { Profile } from '@mp/auth/profile/domain';

import { ProfileActions } from './profile.actions';

export const profileFeatureKey = 'mpauth-core-profile';

export interface ProfileState {
  profile: Profile | null;
}

export const initialState: ProfileState = {
  profile: null,
};

export const profileReducer = createReducer(
  initialState,
  on(
    ProfileActions.fetchProfileSuccess,
    ProfileActions.updateProfileSuccess,
    (state, { profile }): ProfileState => ({
      ...state,
      profile,
    }),
  ),
);
